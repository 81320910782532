import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledTextModule } from '~/components/TextModule/TextModule.style';
import { StyledAutoCarousel } from '../AutoCarousel/AutoCarousel.style';
import { StyledTeamCarousel } from '../TeamCarousel/TeamCarousel.style';

export const StyledOurStoryPage = styled.div`
  background-color: var(--gray);

  p {
    font-size: ${pxtorem(20)};
  }

  .section-label {
    color: var(--black);
    font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
    text-transform: uppercase;
    margin-bottom: ${pxtorem(6)};
  }

  .why-commons {
    ${StyledTextModule} {
      margin-block: ${pxtorem(30)};
      padding: 0 ${pxtorem(18)};

      @media (min-width: 768px) {
        padding: 0 ${pxtorem(27)};
      }

      .text__content {
        margin: 0 auto;
        max-width: 785px;

        h2 {
          margin-bottom: ${pxtorem(22)};
          font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
          line-height: 90%;

          em {
            font-size: clamp(${pxtorem(34)}, 4.333vw, ${pxtorem(52)});
          }
        }

        p {
          font-size: ${pxtorem(16)};
          line-height: ${pxtorem(18)};
          font-weight: 400;
          margin-inline: auto;
          margin-bottom: var(--gutter);

          @media (min-width: 768px) {
            font-size: ${pxtorem(20)};
            font-weight: 300;
            line-height: ${pxtorem(24)};
            width: 65%;
          }

          .signature-title {
            font-size: ${pxtorem(16)};
            line-height: ${pxtorem(18)};
            opacity: 0.7;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-width: 169px;
        }
      }
    }
  }

  .join-the-conversation {
    ${StyledAutoCarousel} {
      padding: ${pxtorem(40)} 0;

      @media (min-width: 768px) {
        padding: ${pxtorem(70)} 0;
      }
    }
  }

  .our-philosophy {
    ${StyledTextModule} {
      @media (min-width: 768px) {
        margin: ${pxtorem(35)} 0 ${pxtorem(25)};
      }
    }
  }

  .team-carousel {
    ${StyledTeamCarousel} {
      @media (max-width: 768px) {
        margin-top: ${pxtorem(30)};
        margin-bottom: ${pxtorem(50)};
      }
    }
  }
`;
