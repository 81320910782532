import React from 'react';

import { ContentfulPageSection } from '~/types/types';

import {
  PageSection,
  ComponentMapExtraData,
  InstanceMapExtraData,
} from '../PageSection/PageSection';

import { BasicHero } from '~/components/BasicHero/BasicHero';
import { StyledOurStoryPage } from './OurStoryPage.style';

const sectionTypesProps: ComponentMapExtraData = {
  'carousel-auto': { componentProps: { slidesPerView: 4 } },
  'pull-quote-blob': {
    sectionProps: { bg: 'green' },
    wrapperProps: { width: 'wide', gutter: false, rounded: false },
    componentProps: { blobColor: 'orange', cardColor: 'gray' },
  },
  'carousel-team': { sectionProps: { bg: 'green' } },
  'featured-protocols': {
    sectionProps: { gutter: true },
    wrapperProps: { bg: 'white' },
  },
  values: {
    sectionProps: { bg: 'gray' },
  },
};

const sectionInstancesProps: InstanceMapExtraData = {
  'why-commons': {
    sectionProps: { gutter: true },
    wrapperProps: { bg: 'white', width: 'normal' },
    componentProps: { centered: true },
  },
};

export const OurStoryPage: React.FC<OurStoryPageProps> = ({
  hero,
  sections,
}) => {
  const heroImage = hero.media?.[0].media.gatsbyImageData;

  return (
    <StyledOurStoryPage>
      <BasicHero image={heroImage} content={hero.description} />

      {sections &&
        sections.map((section, idx) => {
          if (!section?.sectionType) {
            return null;
          }

          const componentData = {
            ...(sectionTypesProps[section.sectionType.slug] || {}),
            ...(sectionInstancesProps[section.slug] || {}),
          };

          return (
            <PageSection
              key={`${section.slug}_${idx}`}
              section={section}
              {...componentData}
            />
          );
        })}
    </StyledOurStoryPage>
  );
};

interface OurStoryPageProps {
  hero: ContentfulPageSection;
  sections: ContentfulPageSection[];
}
