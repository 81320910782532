import React from 'react';
import { graphql } from 'gatsby';
import { OurStoryPage } from '../components/OurStoryPage/OurStoryPage';
import Layout from '../components/Layout/Layout';
import Metadata from '../components/Metadata';
import { ContentfulPageSection } from '~/types/types';

const OurStory: React.FC<OurStoryProps> = ({ data }) => {
  const { page } = data;
  return (
    <Layout floatingHeader>
      <Metadata title={page?.name} />
      <OurStoryPage hero={page.hero} sections={page.sections} />
    </Layout>
  );
};

export interface OurStoryProps {
  data: {
    page: {
      name: string;
      hero: ContentfulPageSection;
      sections: ContentfulPageSection[];
    };
  };
}

export const query = graphql`
  query OurStoryPageQuery {
    page: contentfulPage(slug: { eq: "our-story" }) {
      name
      hero {
        ...ContentSectionFragment
      }
      sections {
        ...ContentSectionFragment
      }
    }
  }
`;

export default OurStory;
